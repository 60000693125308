export default {
  'device.router.name': 'Thiết bị',
  'device.router.nameBreadcrumb': 'Quản lý thiết bị',
  'device.title': 'Quản lý thiết bị phát bản tin',
  'device.table.name': 'Tên thiết bị',
  'device.table.code': 'Mã định danh',
  'device.table.specificAddress': 'Địa chỉ lắp đặt',
  'device.table.operationStatus': 'Trạng thái hoạt động',
  'device.table.connectionStatus': 'Trạng thái kết nối',
  'device.table.playingStatus': 'Trạng thái phát bản tin',
  'device.table.operationStatus.param':
    '{status,select, 1 {Khả dụng} 2 {Không khả dụng} other {Không xác định}}',
  'device.table.connectionStatus.param':
    '{status,select, 1 {Đang kết nối} 2 {Mất kết nối} other {Không xác định}}',
  'device.table.playingStatus.param':
    '{status,select, 1 {Đang phát} 2 {Không phát} 3 {Kiểm tra ngoại tuyến} other {Không xác định}}',
  'device.delete.title': 'Xác nhận xóa thiết bị',
  'device.delete.content': 'Các thiết bị được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'device.restart.title': 'Xác nhận khởi động lại thiết bị',
  'device.restart.content': 'Các thiết bị được chọn sẽ được khởi động lại.',
  'device.volume.title': 'Điều chỉnh âm lượng của thiết bị được chọn',
  'device.filter.operationStatus': 'Trạng thái hoạt động',
  'device.filter.operationStatus.active': 'Khả dụng',
  'device.filter.operationStatus.notActive': 'Không khả dụng',
  'device.filter.connectionStatus': 'Trạng thái kết nối',
  'device.filter.connectionStatus.active': 'Đang kết nối',
  'device.filter.connectionStatus.notActive': 'Mất kết nối',
  'device.filter.playingStatus': 'Trạng thái phát bản tin',
  'device.filter.playingStatus.active': 'Đang phát',
  'device.filter.playingStatus.notActive': 'Không phát',
  'device.filter.playingStatus.undefined': 'Kiểm tra ngoại tuyến',
  'device.qrCode.title': 'Mã đăng nhập thiết bị',
  'device.qrCode.text': 'Quét mã QR hoặc nhập code để đăng nhập thiết bị',
  'device.modal.create': 'Thêm thiết bị',
  'device.modal.update': 'Cập nhật thiết bị',
  'device.modal.create.form.name': 'Tên thiết bị',
  'device.modal.create.form.name.validator': 'Tên thiết bị không đúng định dạng',
  'device.modal.create.form.code': 'Mã định danh thiết bị',
  'device.modal.create.form.code.validator': 'Mã định danh thiết bị không đúng định dạng',
  'device.modal.create.form.subscriberNumber': 'Số thuê bao SIM',
  'device.modal.create.form.serialNumber': 'Số seri SIM',
  'device.modal.create.form.supplierId': 'Nhà cung cấp',
  'device.modal.create.form.operationStatus': 'Trạng thái hoạt động',
  'device.modal.create.form.operationStatus.active': 'Khả dụng',
  'device.modal.create.form.operationStatus.notActive': 'Không khả dụng',
  'device.modal.create.form.description': 'Mô tả',
  'device.router.info.name': 'Thông tin thiết bị',
  'device.info.tabs.detail': 'Thông tin thiết bị',
  'device.info.tabs.broadcast': 'Lịch sử phát bản tin',
  'device.info.tabs.activity': 'Lịch sử hoạt động của thiết bị',
  'device.info.detail.sidebar.title': 'Thông tin thiết bị',
  'device.info.detail.sidebar.name': 'Tên thiết bị',
  'device.info.detail.sidebar.code': 'Mã định danh thiết bị',
  'device.info.detail.sidebar.specificAddress': 'Địa chỉ',
  'device.info.detail.sidebar.subscriberNumber': 'Số thuê bao SIM',
  'device.info.detail.sidebar.serialNumber': 'Số seri SIM',
  'device.info.detail.sidebar.supplierId': 'Nhà cung cấp',
  'device.info.detail.sidebar.sdFree': 'Dung lượng thiết bị',
  'device.info.detail.sidebar.version': 'Phiên bản',
  'device.info.detail.sidebar.operationStatus': 'Trạng thái hoạt động',
  'device.info.detail.sidebar.operationStatus.param':
    '{status,select, 1 {Khả dụng} 2 {Không khả dụng} other {Không xác định}}',
  'device.info.detail.sidebar.connectionStatus': 'Trạng thái kết nối',
  'device.info.detail.sidebar.connectionStatus.param':
    '{status,select, 1 {Đang kết nối} 2 {Mất kết nối} other {Không xác định}}',
  'device.info.detail.sidebar.playingStatus': 'Trạng thái phát bản tin',
  'device.info.detail.sidebar.playingStatus.param':
    '{status,select, 1 {Đang phát} 2 {Không phát} 3 {Kiểm tra ngoại tuyến} other {Không xác định}}',
  'device.info.detail.sidebar.description': 'Mô tả',
  'device.info.detail.sidebar.location': 'Vị trí',
  'device.info.detail.delete.title': 'Xác nhận xóa thiết bị',
  'device.info.detail.delete.content':
    'Các thiết bị được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'device.info.detail.restart.title': 'Xác nhận khởi động lại thiết bị',
  'device.info.detail.restart.content': 'Các thiết bị được chọn sẽ được khởi động lại.',
  'device.info.detail.card.normal': 'Tin thường',
  'device.info.detail.card.prioritize': 'Tin ưu tiên',
  'device.info.detail.card.bulletinStatus.id':
    '{status, select, 1 {Chờ duyệt} 2 {Từ chối duyệt} 3 {Đã hủy} 4 {Chưa phát} 5 {Đang phát} 6 {Tạm ngưng} 7 {Đã kết thúc} 8 {Chờ phát} 9 {Sắp tới lịch} other {--}}',
  'device.info.detail.calendar.day': 'Ngày',
  'device.info.detail.calendar.title': 'Lịch phát thiết bị',
  'device.info.detail.calendar.viewBy': 'Xem theo',
  'device.info.detail.calendar.titleDate': '1. Tin cả ngày',
  'device.info.detail.calendar.myNew': 'Bản tin của tôi',
  'device.info.detail.calendar.fatherNew': 'Bản tin của cơ sở cha',
  'device.info.detail.calendar.cancelNew': 'Bản tin bị hủy',
  'device.info.detail.calendar.viewMore': ' Xem thêm',
  'device.info.detail.calendar.newOther': 'bản tin khác',
  'device.info.detail.calendar.viewLess': 'Ẩn bớt',
  'device.info.detail.calendar.hour': 'Giờ',
  'device.info.detail.calendar.minutes': 'Phút',
  'device.info.detail.calendar.titleTime': '2. Tin theo giờ',
  'device.info.detail.calendar.moreTime': ' Chế độ thời gian',
  'device.info.playLogs.title': 'Lịch sử phát bản tin trên thiết bị',
  'device.info.playLogs.table.newsletter.title': 'Tên bản tin',
  'device.info.playLogs.table.duration': 'Thời lượng',
  'device.info.playLogs.table.startTime': 'Thời gian bắt đầu phát',
  'device.info.playLogs.table.endTime': 'Thời gian kết thúc phát',
  'device.info.playLogs.filter.createdAt': 'Thời gian bắt đầu',
  'device.info.activityHistory.title': 'Lịch sử hoạt động của thiết bị',
  'device.info.activityHistory.table.time': 'Thời gian ',
  'device.info.activityHistory.table.actionType': 'Hoạt động',
  'device.info.activityHistory.table.note': 'Nội dung',
  'device.info.activityHistory.filter.createdAt': 'Thời gian',
  'device.info.activityHistory.table.actionType.downloadSuccessfully': 'Tải bản tin thành công',
  'device.info.activityHistory.table.actionType.downloadError': 'Tải bản tin thất bại',
  'device.info.activityHistory.table.actionType.broadcast': 'Phát bản tin',
  'device.info.activityHistory.table.actionType.paused': 'Tạm dừng bản tin',
  'device.info.activityHistory.table.actionType.requestServer': 'Xóa bản tin',
  'device.info.activityHistory.table.actionType.expired': 'Xóa bản tin hết hạn',
  'device.info.activityHistory.table.actionType.connected': 'Kết nối lại',
  'device.info.activityHistory.table.actionType.disconnected': 'Mất kết nối',
  'device.info.activityHistory.table.actionType.reboot': 'Khởi động lại thiết bị',
  'device.info.activityHistory.table.actionType.adjustVolume': 'Điều chỉnh âm lượng',
  'device.info.activityHistory.table.actionType.newsLetterEnded': 'Bản tin đã kết thúc',
  'device.info.activityHistory.table.actionType.newsLetterResume': 'Phát tiếp bản tin',
  'device.info.activityHistory.table.actionType.changeInfoSource': 'Đổi nguồn thông tin',
  'device.modalInfoLogin.title': 'Xác nhận đăng nhập',
  'device.modalCode.title': 'Nhập mã thiết bị',
  'device.modalCode.text': 'Nhập mã đăng nhập hiển thị trên màn hình thiết bị',
  'device.modalCode.form.code': 'mã thiết bị',
  'device.quantity.label': 'Số lượng thiết bị',
  'device.quantity.warning.title': 'Bạn đã đạt mức giới hạn thiết bị',
  'device.quantity.warning.content':
    'Số lượng thiết bị đã đạt giới hạn của gói cước đang sử dụng, bạn có thể thay đổi gói cước khác hoặc xóa những thiết bị không hoạt động.',
  'device.quantity.warning.btn.upgrade': 'Nâng cấp',
  'device.quantity.warning.message':
    'Bạn không có quyền truy cập chức năng này, hãy liên hệ với chủ tổ chức.',
  'device.modalInfoLogin.text': 'Nếu bạn đăng nhập, thiết bị đã kết nối sẽ tự động đăng xuất.',
  'device.login-device': 'Đăng nhập',
  'device.refuse': 'Từ chối',
  'device.time': 'Thời gian',
  'device.address': 'Địa điểm',
  'device.login-qr': 'Đăng nhập thiết bị',
  'device.inform.title': 'Thông báo',
  'device.inform.content':
    'Bạn đang tiếp cận giới hạn thiết bị của kế hoạch sắp tới. Theo kế hoạch đến ngày {date}, gói cước mới sẽ giới hạn ở {deviceQuantity} thiết bị. Bạn có thể cân nhắc điều chỉnh gói cước phù hợp hơn.',
  'device.inform.okText': 'Quản lý gói cước',
  'device.table.deviceGroup': 'Nhóm thiết bị',
  'device.modal.create.form.deviceGroup': 'Nhóm thiết bị',
  'device.info.detail.sidebar.deviceGroup': 'Nhóm thiết bị',
  'device.filter.deviceGroups': 'Nhóm thiết bị',
  'device.info.activityHistory.filter.actionType': 'Hoạt động',
  'device.info.activityHistory.filter.actionType.downloadSuccessfully': 'Tải bản tin thành công',
  'device.info.activityHistory.filter.actionType.downloadError': 'Tải bản tin thất bại',
  'device.info.activityHistory.filter.actionType.broadcast': 'Phát bản tin',
  'device.info.activityHistory.filter.actionType.paused': 'Tạm dừng bản tin',
  'device.info.activityHistory.filter.actionType.requestServer': 'Xóa bản tin',
  'device.info.activityHistory.filter.actionType.expired': 'Xóa bản tin hết hạn',
  'device.info.activityHistory.filter.actionType.connected': 'Kết nối lại',
  'device.info.activityHistory.filter.actionType.disconnected': 'Mất kết nối',
  'device.info.activityHistory.filter.actionType.reboot': 'Khởi động lại thiết bị',
  'device.info.activityHistory.filter.actionType.adjustVolume': 'Điều chỉnh âm lượng',
  'device.info.activityHistory.filter.actionType.newsLetterEnded': 'Bản tin đã kết thúc',
  'device.info.activityHistory.filter.actionType.newsLetterResume': 'Phát tiếp bản tin',
  'device.info.activityHistory.filter.actionType.changeInfoSource': 'Đổi nguồn thông tin',
  'device.info.action.login.name': 'Đăng nhập thiết bị',
  'device.info.detail.sidebar.ipAddress': 'Địa chỉ IP',
  'device.info.activityHistory.table.actionType.deleteFile': 'Xóa tập tin phát bản tin',
  'device.info.activityHistory.table.actionType.deleteFileError':
    'Xóa tập tin phát bản tin không thành công',
  'device.info.activityHistory.table.actionType.networkDisconnected': 'Mất kết nối mạng',
  'device.info.activityHistory.filter.actionType.deleteFile': 'Xóa tập tin phát bản tin',
  'device.info.activityHistory.filter.actionType.deleteFileError':
    'Xóa tập tin phát bản tin không thành công',
  'device.info.activityHistory.filter.actionType.networkDisconnected': 'Mất kết nối mạng',
  'device.info.activityHistory.table.actionType.deviceInvalidConnectSession':
    'Thời gian kết nối không hợp lệ',
  'device.info.activityHistory.filter.actionType.deviceInvalidConnectSession':
    'Thời gian kết nối không hợp lệ',
  //
  'device.action.label.screenshot': 'Chụp màn hình thiết bị',
  'device.action.label.screenshot.popup.title': 'Xác nhận chụp màn hình',
  'device.action.label.screenshot.popup.content':
    'Bạn chắc muốn chụp màn hình thiết bị hiện tại của thiết bị?',
  'device.action.label.screenshot.popup.failed.title': 'Chụp màn hình không thành công',
  'device.action.label.screenshot.popup.failed.content':
    'Bạn có muốn tiến hành chụp lại màn hình của thiết bị không?',
  'device.action.label.screenshot.popup.failed.cancelText': 'Chụp lại',
  'device.modal.screenshot.title': 'Chụp màn hình',
  'device.modal.screenshot.text':
    'Có thể xem lại hình đã chụp ở <strong>Lịch sử hoạt động của thiết bị</strong>',
  'device.info.action.label.screenshot': 'Chụp màn hình thiết bị',
  'device.info.action.label.screenshot.popup.title': 'Xác nhận chụp màn hình',
  'device.info.action.label.screenshot.popup.content':
    'Bạn chắc muốn chụp màn hình thiết bị hiện tại của thiết bị?',
  'device.info.action.label.screenshot.popup.failed.title': 'Chụp màn hình không thành công',
  'device.info.action.label.screenshot.popup.failed.content':
    'Bạn có muốn tiến hành chụp lại màn hình của thiết bị không?',
  'device.info.action.label.screenshot.popup.failed.cancelText': 'Chụp lại',
  'device.info.activityHistory.table.actionType.deviceScreenshot': 'Chụp màn hình',
  'device.info.activityHistory.table.actionType.screenshotError': 'Chụp màn hình không thành công',
  'device.info.activityHistory.filter.actionType.deviceScreenshot': 'Chụp màn hình',
  'device.info.activityHistory.filter.actionType.screenshotError': 'Chụp màn hình không thành công',
};
