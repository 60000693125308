export default {
  'dashboard.router.manager': 'Dashboard',
  'dashboard.summary.organizations': 'Tổ chức',
  'dashboard.summary.organizations.expired': 'Tổ chức hết hạn',
  'dashboard.summary.organizations.due': 'Tổ chức còn hạn',
  'dashboard.summary.capacityUsed.text': 'Dung lượng tổ chức đã sử dụng',
  'dashboard.summary.capacity.text': 'Dung lượng tổ chức đã đăng ký',
  'dashboard.filter.organizations': 'Tổ chức',
  'dashboard.filter.status': 'Trạng thái hoạt động',
  'dashboard.filter.status.active': 'Khả dụng',
  'dashboard.filter.status.notActive': 'Không khả dụng',
  'dashboard.marker.device.name': 'Tên thiết bị',
  'dashboard.marker.organizations': 'Tổ chức',
  'dashboard.marker.specificAddress': 'Địa chỉ',
  'dashboard.marker.latitude': 'Vĩ độ',
  'dashboard.marker.longitude': 'Kinh độ',
  'dashboard.marker.status': 'Trạng thái hoạt động',
  'dashboard.marker.device.info': 'Thông tin thiết bị',
  'dashboard.marker.devices.operationStatus.id':
    '{status,select, 1 {Khả dụng} 2 {Không khả dụng} other {Không xác định}}',
  'dashboard.summary.facilities': 'Cơ sở phát',
  'dashboard.summary.totalDevice': 'Thiết bị',
  'dashboard.summary.totalNewsletter': 'Bản tin',
  'dashboard.summary.capacity': 'Dung lượng',
  'dashboard.summary.totalDevice.label': 'Tối đa',
  'dashboard.summary.totalDevice.valueDesc': 'thiết bị',
  'dashboard.device.title': 'Dashboard thiết bị',
  'dashboard.device.name': 'Thiết bị',
  'dashboard.device.facility.name': 'Cơ sở',
  'dashboard.device.deviceGroups': 'Nhóm thiết bị',
  'dashboard.device.connectionStatus': 'Trạng thái kết nối',
  'dashboard.device.connectionStatus.connecting': 'Đang kết nối',
  'dashboard.device.connectionStatus.notConnectedYet': 'Mất kết nối',
  'dashboard.device.playingStatus': 'Trạng thái phát bản tin',
  'dashboard.device.playingStatus.playing': 'Đang phát',
  'dashboard.device.playingStatus.notScheduledYet': 'Không phát',
  'dashboard.device.playingStatus.undefined': 'Kiểm tra ngoại tuyến',
  'dashboard.device.operationStatus': 'Trạng thái hoạt động',
  'dashboard.device.operationStatus.active': 'Khả dụng',
  'dashboard.device.operationStatus.deactive': 'Không khả dụng',
  'dashboard.device.information': 'Thông tin',
  'dashboard.device.filter.connectionStatus': 'Trạng thái kết nối',
  'dashboard.device.filter.connectionStatus.connecting': 'Đang kết nối',
  'dashboard.device.filter.connectionStatus.notConnectedYet': 'Mất kết nối',
  'dashboard.device.filter.playingStatus': 'Trạng thái phát bản tin',
  'dashboard.device.filter.playingStatus.playing': 'Đang phát',
  'dashboard.device.filter.playingStatus.notScheduledYet': 'Không phát',
  'dashboard.device.filter.playingStatus.undefined': 'Kiểm tra ngoại tuyến',
  'dashboard.device.filter.operationStatus': 'Trạng thái hoạt động',
  'dashboard.device.filter.operationStatus.active': 'Khả dụng',
  'dashboard.device.filter.operationStatus.deactive': 'Không khả dụng',
  'dashboard.device.chart.title.connectionStatus': 'Tỷ lệ kết nối',
  'dashboard.device.chart.title.playingStatus': 'Phát bản tin',
  'dashboard.device.chart.title.operationStatus': 'Tỷ lệ hoạt động',
  'dashboard.device.chart.title': 'Danh sách thiết bị',
  'dashboard.device.export': 'Xuất file excel',
  'dashboard.marker.device.deviceGroup': 'Nhóm thiết bị',
  'dashboard.device.router.nameBreadcrumb': 'Dashboard thiết bị',
  'dashboard.device.connectionStatus.param':
    '{status,select, 1 {Đang kết nối} 2 {Mất kết nối} other {Không xác định}}',
  'dashboard.device.playingStatus.param':
    '{status,select, 1 {Đang phát} 2 {Không phát} 3 {Kiểm tra ngoại tuyến} other {Không xác định}}',
  'dashboard.device.operationStatus.param':
    '{status,select, 1 {Khả dụng} 2 {Không khả dụng} other {Không xác định}}',
  'dashboard.device.filter.deviceGroup': 'Nhóm thiết bị',
  'dashboard.device.filter.deviceGroup.placeholder': 'Vui lòng chọn nhóm thiết bị',
  'dashboard.device.filter.facility': 'Cơ sở',
  'dashboard.device.filter.facility.placeholder': 'Vui lòng chọn cơ sở',
  'dashboard.device.chart.head.title': 'Tổng số thiết bị',
  'dashboard.device.chart.device.unit': 'thiết bị',
  'dashboard.device.chart.empty.des': 'Không có dữ liệu',
};
