export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AccountEmail.NotExists': 'Incorrect login information',
  'Server.AddBeat.Success': 'Successfully added',
  'Server.DeleteBeat.Success': 'Successfully deleted',
  'Server.UpdateBeat.Success': 'Successfully updated',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Incorrect username or password',
  'Mes.Users.Login.Successfully': 'Successfully logged in',
  'Mes.Classes.Year.MustHave2Characters': 'Academic year must have 2 characters',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Class code must have 2 characters',
  'Mes.Classes.Add.Successfully': 'Successfully added class',
  'Mes.Questions.Update.Successfully': 'Successfully updated question',
  'Mes.Questions.Add.Successfully': 'Successfully added question',
  'Mes.Users.UpdateSuccessfully': 'Successfully updated account information',
  'Mes.Users.Add.Successfully': 'Successfully added account',
  'Mes.Users.DeleteUsers.Successfully': 'Successfully deleted',
  'Mes.Researchs.Update.Successfully': 'Successfully updated research article',
  'Mes.Researchs.Add.Successfully': 'Successfully added research article',
  'Mes.Researchs.Delete.Successfully': 'Successfully deleted research article',
  'server.data.success': 'Success',
  Successful: 'Successful',
  'Mes.Facility.AlreadyExist.Media': 'Media has already been shared in the location',
  'Mes.User.SendPasswordRecoveryCodeSuccessfully':
    'Password recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Password reset successfully',
  'Mes.Profile.GetDetailSuccessfully': 'Successfully retrieved account information',
  'Mes.Profile.OldPassword.Wrong': 'Old password is incorrect',
  'Mes.Profile.ChangePasswordSuccessfully': 'Password successfully updated',
  'Mes.Role.UpdateSuccessfully': 'Successfully updated role',
  'Mes.Role.CreateSuccessfully': 'Successfully added role',
  'Mes.Role.DeleteSuccessfully': 'Successfully deleted role',
  'Mes.Newsletter.Delete.Successfully': 'Successfully deleted broadcast',
  'Mes.Newsletter.Cancel.Successfully': 'Successfully canceled broadcast',
  'Mes.Newsletter.Create.Successfully': 'Successfully added broadcast',
  'Mes.Newsletter.Invalid.ApprovalStatus': 'Invalid broadcast approval status',
  'Mes.Template.Create.Successfully': 'Successfully added template',
  'Mes.Media.Create.Successfully': 'Successfully added media',
  'Mes.MediaShare.Delete.Successfully': 'Successfully deleted shared media',
  'Mes.Playlist.Create.Successfully': 'Successfully added playlist',
  'Mes.BroadcastFacility.AlreadyExist.Newsletter': 'Broadcast facility already has a broadcast',
  'Mes.Role.Create.Successfully': 'Successfully created role',
  'Mes.Role.Create.Failed': 'Failed to create role',
  'Mes.Role.Update.Successfully': 'Successfully updated role',
  'Mes.Role.Update.Failed': 'Failed to update role',
  'Mes.Role.Search.Successfully': 'Successfully viewed role list',
  'Mes.Role.ViewDetail.Successfully': 'Successfully viewed detailed information',
  'Mes.Role.Delete.Successfully': 'Successfully deleted role',
  'Mes.Role.Delete.Failed': 'Failed to delete role',
  'Mes.Role.Required.PermissionCodes': 'Function codes are required',
  'Mes.Role.OverLength.Code': 'Role code must not exceed 255 characters',
  'Mes.Role.OverLength.Description': 'Role description must not exceed 255 characters',
  'Mes.Role.AlreadyExist.Code': 'Role code already exists',
  'Mes.Role.Required.Code': 'Role code is required',
  'Mes.Role.NotWhiteSpace.Code': 'Role code must not contain whitespace',
  'Mes.Role.NotSpecialCharacter.Code':
    'Role code must not contain special characters (except . and @)',
  'Mes.Role.Required.Name': 'Role name is required',
  'Mes.Role.OverLength.Name': 'Role name must not exceed 255 characters',
  'Mes.Role.NotFound': 'Role not found',
  'Mes.Role.Repeated.Ids': 'Role ID must not be repeated',
  'Mes.Role.Required.Ids': 'Role ID is required',
  'Mes.Role.WasUsed': 'Role has been used',
  'Mes.User.Create.Successfully': 'Successfully created user',
  'Mes.User.Create.Failed': 'Failed to create user',
  'Mes.User.Update.Successfully': 'Successfully updated user',
  'Mes.User.Update.Failed': 'Failed to update user',
  'Mes.User.Search.Successfully': 'Successfully viewed user list',
  'Mes.User.ViewDetail.Successfully': 'Successfully viewed detailed information',
  'Mes.User.Delete.Successfully': 'Successfully deleted user',
  'Mes.User.Delete.Failed': 'Failed to delete user',
  'Mes.User.Login.Successfully': 'Successfully logged in',
  'Mes.User.Send.Failed': 'Failed to send email to user',
  'Mes.User.CheckRecoveryCode.Successfully': 'Successfully checked recovery code',
  'Mes.User.SendRecoveryCode.Successfully': 'Successfully sent recovery code',
  'Mes.User.ResetPassword.Successfully': 'Successfully recovered password',
  'Mes.User.Refresh.Successfully': 'Successfully refreshed',
  'Mes.User.ChangePassword.Successfully': 'Successfully changed password',
  'Mes.User.Profile.Successfully': 'Successfully retrieved profile information',
  'Mes.User.Required.Code': 'Login code is required',
  'Mes.User.Invalid.Username': 'Invalid username',
  'Mes.User.AlreadyExist.Username': 'Username already exists',
  'Mes.User.Required.Username': 'Username is required',
  'Mes.User.NotWhiteSpace.Username': 'Username must not contain whitespace',
  'Mes.User.NotSpecialCharacter.Username':
    'Username must not contain special characters (except . and @)',
  'Mes.User.Invalid.Email': 'Invalid email',
  'Mes.User.AlreadyExist.Email': 'Email already exists',
  'Mes.User.Required.Email': 'Email is required',
  'Mes.User.NotWhiteSpace.Email': 'Email must not contain whitespace',
  'Mes.User.OverLength.Name': 'User name must not exceed 250 characters',
  'Mes.User.Invalid.AvatarPicture': 'Invalid avatar picture',
  'Mes.User.Invalid.Password':
    'Password must be at least 8 characters, including lowercase letters, uppercase letters, numbers, and special characters.',
  'Mes.User.Required.Password': 'Password is required',
  'Mes.User.IsWrong.OldPassword': 'Old password is incorrect',
  'Mes.User.Invalid.OldPassword': 'Old password is invalid',
  'Mes.User.Invalid.PhoneNumber': 'Invalid phone number',
  'Mes.User.Required.RoleId': 'Role ID is required',
  'Mes.User.Status.Required': 'Status is required',
  'Mes.User.Status.Invalid': 'Invalid status',
  'Mes.User.Required.RecoveryCode': 'Recovery code is required',
  'Mes.User.NotFound.RecoveryCode': 'User recovery code not found',
  'Mes.User.NotFound': 'User not found',
  'Mes.User.PleaseCheckEmail': 'Please check your email',
  'Mes.User.Blocked': 'User has been blocked',
  'Mes.UserRefreshToken.Required.Token': 'Refresh token is required',
  'Mes.UserRefreshToken.WasUsed': 'Refresh token has been used',
  'Mes.UserRefreshToken.Invalid.Token': 'Invalid refresh token',
  'Mes.UserRefreshToken.Expired.Token': 'Refresh token has expired',
  'Mes.BroadcastDevice.AlreadyExist.Newsletter': 'Broadcast location already has the broadcast',
  'Mes.TimeSlot.Required.StartTime': 'Start time is required',
  'Mes.TimeSlot.Required.EndTime': 'End time is required',
  'Mes.TimeSlot.Invalid.EndTime': 'Invalid end time',
  'Mes.TimeSlot.Required.LoopCount': 'Loop count is required',
  'Mes.TimeSlot.Invalid.LoopCount': 'Invalid loop count',
  'Mes.Schedule.Required.TimeSlots': 'Time slots are required',
  'Mes.Schedule.Repeated.TimeSlots': 'Duplicate time slots',
  'Mes.Schedule.Required.FinalDate': 'Final date is required',
  'Mes.Schedule.Required.ReleaseDate': 'Release date is required',
  'Mes.Schedule.Invalid.ReleaseDate': 'Invalid release date',
  'Mes.Schedule.Invalid.FinalDate': 'Invalid final date',
  'Mes.Schedule.Invalid.RepeatType': 'Invalid repeat type',
  'Mes.Schedule.Required.RepeatType': 'Repeat type is required',
  'Mes.Schedule.MustBeEmpty.RepeatValue': 'Repeat value must be empty',
  'Mes.Schedule.Invalid.RepeatValue': 'Invalid repeat value',
  'Mes.Schedule.Required.RepeatValue': 'Repeat value is required',
  'Mes.Schedule.MustBeEmpty.FinalDate': 'Final date must be empty',
  'Mes.Schedule.MustBeEmpty.TimeSlots': 'Time slots must be empty',
  'Mes.Schedule.MustBeEmpty.RepeatType': 'Repeat type must be empty',
  'Mes.Newsletter.Expired.Duration': 'Duration has expired',
  'Mes.Newsletter.Expired.Schedule': 'Schedule has expired',
  'Mes.Newsletter.Expired.Playlist': 'Playlist has expired',
  'Mes.Newsletter.Expired.PlaylistId': 'Playlist ID has expired',
  'Mes.Newsletter.Expired.Template': 'Template has expired',
  'Mes.Newsletter.Expired.TemplateId': 'Template ID has expired',
  'Mes.Newsletter.Expired.Media': 'Media has expired',
  'Mes.Newsletter.Expired.MediaId': 'Media ID has expired',
  'Mes.Newsletter.Expired.PlayingStatus': 'Broadcast status has expired',
  'Mes.Newsletter.Expired.ApprovalStatus': 'Approval status has expired',
  'Mes.Newsletter.Expired.SourceType': 'Media type has expired',
  'Mes.Newsletter.Expired.Type': 'Type has expired',
  'Mes.Newsletter.Expired.Field': 'Field has expired',
  'Mes.Newsletter.Expired.FieldId': 'Field ID has expired',
  'Mes.Newsletter.Expired.Facility': 'Location has expired',
  'Mes.Newsletter.Expired.FacilityId': 'Location code has expired',
  'Mes.Newsletter.Expired.Organization': 'Account has expired',
  'Mes.Newsletter.Expired.OrganizationId': 'Account ID has expired',
  'Mes.Newsletter.Expired.ReasonRefusal': 'Reason for refusal has expired',
  'Mes.Newsletter.Expired.Title': 'Title has expired',
  'Mes.Newsletter.AlreadyExist.Id': 'ID already exists',
  'Mes.Newsletter.AlreadyExist.CreationMode': 'Creation mode already exists',
  'Mes.Newsletter.AlreadyExist.Duration': 'Duration already exists',
  'Mes.Newsletter.AlreadyExist.Schedule': 'Schedule already exists',
  'Mes.Newsletter.AlreadyExist.Playlist': 'Playlist already exists',
  'Mes.Newsletter.AlreadyExist.PlaylistId': 'Playlist ID already exists',
  'Mes.Newsletter.AlreadyExist.Template': 'Template already exists',
  'Mes.Newsletter.AlreadyExist.TemplateId': 'Template ID already exists',
  'Mes.Newsletter.AlreadyExist.Media': 'Media already exists',
  'Mes.Newsletter.AlreadyExist.MediaId': 'Media ID already exists',
  'Mes.Newsletter.AlreadyExist.PlayingStatus': 'Broadcast status already exists',
  'Mes.Newsletter.AlreadyExist.ApprovalStatus': 'Approval status already exists',
  'Mes.Newsletter.AlreadyExist.SourceType': 'Media type already exists',
  'Mes.Newsletter.AlreadyExist.Type': 'Type already exists',
  'Mes.Newsletter.AlreadyExist.Field': 'Category already exists',
  'Mes.Newsletter.AlreadyExist.FieldId': 'Category ID already exists',
  'Mes.Newsletter.AlreadyExist.Facility': 'Location already exists',
  'Mes.Newsletter.AlreadyExist.FacilityId': 'Location code already exists',
  'Mes.Newsletter.AlreadyExist.Organization': 'Account already exists',
  'Mes.Newsletter.AlreadyExist.OrganizationId': 'Account ID already exists',
  'Mes.Newsletter.AlreadyExist.ReasonRefusal': 'Reason for refusal already exists',
  'Mes.Newsletter.AlreadyExist.Title': 'Title already exists',
  'Mes.Newsletter.NotSpecialCharacter.Title': 'Title must not contain special characters',
  'Mes.Newsletter.NotWhiteSpace.Title': 'Title must not contain whitespace',
  'Mes.Newsletter.OverLength.Id': 'ID is too long',
  'Mes.Newsletter.OverLength.ReasonRefusal': 'Reason for refusal is too long',
  'Mes.Newsletter.OverLength.Title': 'Title is too long',
  'Mes.Newsletter.Required.Id': 'ID is required',
  'Mes.Newsletter.Required.CreationMode': 'Creation mode is required',
  'Mes.Newsletter.Required.Duration': 'Duration is required',
  'Mes.Newsletter.Required.Schedule': 'Schedule is required',
  'Mes.Newsletter.Required.Playlist': 'Playlist is required',
  'Mes.Newsletter.Required.PlaylistId': 'Playlist ID is required',
  'Mes.Newsletter.Required.Template': 'Template is required',
  'Mes.Newsletter.Required.TemplateId': 'Template ID is required',
  'Mes.Newsletter.Required.Media': 'Media is required',
  'Mes.Newsletter.Required.MediaId': 'Media ID is required',
  'Mes.Newsletter.Required.PlayingStatus': 'Broadcast status is required',
  'Mes.Newsletter.Required.ApprovalStatus': 'Approval status is required',
  'Mes.Newsletter.Required.SourceType': 'Media type is required',
  'Mes.Newsletter.Required.Type': 'Type is required',
  'Mes.Newsletter.Required.FieldId': 'Category ID is required',
  'Mes.Newsletter.Required.FacilityId': 'Location code is required',
  'Mes.Newsletter.Required.OrganizationId': 'Account ID is required',
  'Mes.Newsletter.Required.ReasonRefusal': 'Reason for refusal is required',
  'Mes.Newsletter.Required.Title': 'Title is required',
  'Mes.Newsletter.MustBeEmpty.Duration': 'Duration must be empty',
  'Mes.Newsletter.Invalid': 'Invalid broadcast ',
  'Mes.Newsletter.Invalid.CreationMode': 'Invalid creation mode',
  'Mes.Newsletter.Invalid.Creator': 'Invalid creator',
  'Mes.Newsletter.Invalid.CreatorId': 'Invalid creator ID',
  'Mes.Newsletter.Invalid.Duration': 'Invalid duration',
  'Mes.Newsletter.Invalid.Schedule': 'Invalid schedule',
  'Mes.Newsletter.Invalid.Playlist': 'Invalid playlist',
  'Mes.Newsletter.Invalid.PlaylistId': 'Invalid playlist ID',
  'Mes.Newsletter.Invalid.Template': 'Invalid template',
  'Mes.Newsletter.Invalid.TemplateId': 'Invalid template ID',
  'Mes.Newsletter.Invalid.Media': 'Invalid media',
  'Mes.Newsletter.Invalid.MediaId': 'Invalid media ID',
  'Mes.Newsletter.Invalid.PlayingStatus': 'Invalid broadcast  status',
  'Mes.Newsletter.Invalid.SourceType': 'Invalid media type',
  'Mes.Newsletter.Invalid.Type': 'Invalid type',
  'Mes.Newsletter.Invalid.Field': 'Invalid category',
  'Mes.Newsletter.Invalid.FieldId': 'Invalid category ID',
  'Mes.Newsletter.Invalid.Facility': 'Invalid location',
  'Mes.Newsletter.Invalid.FacilityId': 'Invalid Location code',
  'Mes.Newsletter.Invalid.Organization': 'Invalid account',
  'Mes.Newsletter.Invalid.OrganizationId': 'Invalid account ID',
  'Mes.Newsletter.Invalid.ReasonRefusal': 'Invalid reason for refusal',
  'Mes.Newsletter.Invalid.Title': 'Invalid title',
  'Mes.Newsletter.Repeated.Id': 'Duplicate ID',
  'Mes.Newsletter.Repeated.CreationMode': 'Duplicate creation mode',
  'Mes.Newsletter.Repeated.Creator': 'Duplicate creator',
  'Mes.Newsletter.Repeated.CreatorId': 'Duplicate creator ID',
  'Mes.Newsletter.Repeated.Duration': 'Duplicate duration',
  'Mes.Newsletter.Repeated.Schedule': 'Duplicate schedule',
  'Mes.Newsletter.Repeated.Playlist': 'Duplicate playlist',
  'Mes.Newsletter.Repeated.PlaylistId': 'Duplicate playlist ID',
  'Mes.Newsletter.Repeated.Template': 'Duplicate template',
  'Mes.Newsletter.Repeated.TemplateId': 'Duplicate template ID',
  'Mes.Newsletter.Repeated.Media': 'Duplicate media',
  'Mes.Newsletter.Repeated.MediaId': 'Duplicate media ID',
  'Mes.Newsletter.Repeated.PlayingStatus': 'Duplicate playing status',
  'Mes.Newsletter.Repeated.ApprovalStatus': 'Duplicate approval status',
  'Mes.Newsletter.Repeated.SourceType': 'Duplicate media type',
  'Mes.Newsletter.Repeated.Type': 'Duplicate type',
  'Mes.Newsletter.Repeated.Field': 'Duplicate category',
  'Mes.Newsletter.Repeated.FieldId': 'Duplicate category ID',
  'Mes.Newsletter.Repeated.Facility': 'Duplicate location',
  'Mes.Newsletter.Repeated.FacilityId': 'Duplicate Location code',
  'Mes.Newsletter.Repeated.Organization': 'Duplicate account',
  'Mes.Newsletter.Repeated.OrganizationId': 'Duplicate account ID',
  'Mes.Newsletter.Repeated.ReasonRefusal': 'Repeated reason for refusal',
  'Mes.Newsletter.Repeated.Title': 'Duplicate title',
  'Mes.Newsletter.NotFound.Id': 'ID not found',
  'Mes.Newsletter.NotFound.CreatorId': 'Creator ID not found',
  'Mes.Newsletter.NotFound.PlaylistId': 'Playlist ID not found',
  'Mes.Newsletter.NotFound.TemplateId': 'Template ID not found',
  'Mes.Newsletter.NotFound.MediaId': 'Media ID not found',
  'Mes.Newsletter.NotFound.Field': 'Category not found',
  'Mes.Newsletter.NotFound.FieldId': 'Category ID not found',
  'Mes.Newsletter.NotFound.FacilityId': 'Location code not found',
  'Mes.Newsletter.NotFound.OrganizationId': 'Account ID not found',
  'Mes.Newsletter.NotFound': 'Broadcast not found',
  'Mes.Newsletter.WasUsed': 'Already used',
  'Mes.Newsletter.NotAllowed': 'Broadcast not allowed',
  'Mes.Newsletter.Blocked': 'Broadcast blocked',
  'Mes.Newsletter.Import.Failed': 'Broadcast import failed',
  'Mes.Newsletter.Import.Successfully': 'Broadcast import successful',
  'Mes.Newsletter.Delete.Failed': 'Broadcast deletion failed',
  'Mes.Newsletter.Search.Failed': 'Broadcast search failed',
  'Mes.Newsletter.Search.Successfully': 'Broadcast search successful',
  'Mes.Newsletter.ViewList.Failed': 'View broadcast list failed',
  'Mes.Newsletter.ViewList.Successfully': 'View broadcast list successful',
  'Mes.Newsletter.ViewDetail.Failed': 'View broadcast detail failed',
  'Mes.Newsletter.ViewDetail.Successfully': 'View broadcast detail successful',
  'Mes.Newsletter.Update.Failed': 'Broadcast update failed',
  'Mes.Newsletter.Update.Successfully': 'Broadcast update successful',
  'Mes.Newsletter.Create.Failed': 'Broadcast creation failed',
  'Mes.Newsletter.OverTime.FinalDate': 'Broadcast past final date',
  'Mes.Newsletter.Aprrove.Successfully': 'Broadcast approval successful',
  'Mes.Newsletter.Aprrove.Failed': 'Broadcast approval failed',
  'Mes.Newsletter.Cancel.Failed': 'Broadcast cancellation failed',
  'Mes.Newsletter.MustBeEmpty.FacilityIds': 'Location codes must be empty',
  'Mes.Newsletter.Required.FacilityIds': 'Location codes are required',
  'Mes.Newsletter.MustBeEmpty.DeviceIds': 'Device IDs must be empty',
  'Mes.Newsletter.Required.DeviceIds': 'Device IDs are required',
  'Mes.User.Required.TimezoneId': 'Timezone ID is required',
  'Mes.User.Required.NotFound': 'Timezone not found',
  'Mes.User.Required.RedirectUri': 'Redirect URL is required',
  'Mes.User.OverLength.RedirectUri': 'Redirect URL cannot exceed 255 characters',
  'Mes.User.OverLength.Code': 'Login code cannot exceed 255 characters',
  'Mes.User.Required.PhoneNumber': 'Phone number is required',
  'Mes.User.OverLength.IdentifierNumber': 'ID card number cannot exceed 50 characters',
  'Mes.User.Invalid.IdentifierNumber': 'Invalid ID card number',
  'Mes.User.Repeated.Ids': 'Duplicate user ID',
  'Mes.User.Required.Ids': 'User ID is required',
  'Mes.User.NotAllowed': 'User not allowed',
  'Mes.SsoResult.Empty.Id': 'User ID not found on SSO',
  'Mes.SsoAuthentication.Failed': 'SSO login failed',
  'Mes.SsoResult.Empty.UserName': 'Username not found on SSO',
  'Messages.SsoAuthentication.Required.AccessToken': 'Access token is required for SSO login',
  'Mes.SsoAuthentication.Required.AccessToken': 'Access token is required for SSO login',
  'Mes.SsoAuthentication.Invalid.Email': 'Invalid email for SSO',
  'Mes.SsoAuthentication.Invalid.PhoneNumber': 'Invalid phone number for SSO',
  'Mes.SsoAuthentication.Invalid.Username': 'Invalid username for SSO',
  'Mes.SsoAuthentication.Invalid.Groups':
    'Invalid SSO user group, please contact the system administrator!',
  'Mes.SsoAuthentication.Invalid.ClientId':
    'Invalid Client ID, please contact the system administrator!',
  'Mes.SsoAuthentication.Invalid.ClientSecret':
    'Invalid Client Secret, please contact the system administrator!',
  'Mes.SsoAuthentication.Invalid.GrantType':
    'Invalid Grant Type, please contact the system administrator!',
  'Mes.UserMobileDevice.Required.DeviceId': 'Device registration ID is required',
  'Mes.UserMobileDevice.OverLength.DeviceId':
    'Device registration ID must not exceed 255 characters',
  'Mes.Field.AlreadyExist.Code': 'Category code already exists',
  'Mes.Field.Required.Ids': 'Category ID is required',
  'Mes.Field.Repeated.Id': 'Repeated category ID',
  'Mes.Field.NotFound': 'Category not found',
  'Mes.Field.Required.Name': 'Category name is required',
  'Mes.Field.OverLength.Name': 'Category name is too long',
  'Mes.Field.Required.LoginCode': 'Login code is required',
  'Mes.Field.Invalid.LoginCode': 'Invalid login code',
  'Mes.Field.Required.Code': 'Category code is required',
  'Mes.Field.OverLength.Code': 'Category code is too long',
  'Mes.Field.NotWhiteSpace.Code': 'Category code must not contain white spaces',
  'Mes.Field.NotSpecialCharacter.Code': 'Category code must not contain special characters',
  'Mes.Field.Delete.Failed': 'Failed to delete category',
  'Mes.Field.Delete.Successfully': 'Successfully deleted category',
  'Mes.Field.Search.Failed': 'Failed to search category',
  'Mes.Field.Search.Successfully': 'Successfully searched category',
  'Mes.Field.ViewList.Failed': 'Failed to view category list',
  'Mes.Field.ViewList.Successfully': 'Successfully viewed category list',
  'Mes.Field.ViewDetail.Failed': 'Failed to view category details',
  'Mes.Field.ViewDetail.Successfully': 'Successfully viewed category details',
  'Mes.Field.Update.Failed': 'Failed to update category',
  'Mes.Field.Update.Successfully': 'Successfully updated category',
  'Mes.Field.Create.Failed': 'Failed to create category',
  'Mes.Field.Create.Successfully': 'Successfully created category',
  'Mes.Field.WasUsed': 'Category has been used',
  'Mes.Announcement.Required.Subject': 'Notification subject is required',
  'Mes.Announcement.OverLength.Subject': 'Notification subject is too long',
  'Mes.Announcement.Required.Content': 'Notification content is required',
  'Mes.Announcement.OverLength.Content': 'Notification content is too long',
  'Mes.Announcement.NotEnough.SendTime': 'Not time to send yet',
  'Mes.Announcement.Required.OrganizationIds': 'Account ID is required',
  'Mes.Announcement.Repeated.OrganizationIds': 'Repeated account ID',
  'Mes.Announcement.Required.Channels': 'Notification channel is required',
  'Mes.Announcement.Repeated.Channels': 'Repeated notification channel',
  'Mes.Announcement.Invalid.Channels': 'Invalid notification channel',
  'Mes.Announcement.NotFound': 'Notification not found',
  'Mes.Announcement.WasSent': 'Notification has been sent',
  'Mes.Announcement.Delete.Failed': 'Failed to delete notification',
  'Mes.Announcement.Delete.Successfully': 'Successfully deleted notification',
  'Mes.Announcement.Search.Failed': 'Failed to search notification',
  'Mes.Announcement.Search.Successfully': 'Successfully searched notification',
  'Mes.Announcement.ViewList.Failed': 'Failed to view notification list',
  'Mes.Announcement.ViewList.Successfully': 'Successfully viewed notification list',
  'Mes.Announcement.ViewDetail.Failed': 'Failed to view notification details',
  'Mes.Announcement.ViewDetail.Successfully': 'Successfully viewed notification details',
  'Mes.Announcement.Update.Failed': 'Failed to update notification',
  'Mes.Announcement.Update.Successfully': 'Successfully updated notification',
  'Mes.Announcement.Create.Failed': 'Failed to create notification',
  'Mes.Announcement.Create.Successfully': 'Successfully created notification',
  'Mes.Notification.Required.Ids': 'Notification ID is required',
  'Mes.Notification.Repeated.Ids': 'Repeated notification ID',
  'Mes.Notification.Invalid.Group': 'Invalid notification channel',
  'Mes.Notification.Required.Group': 'Account ID is required',
  'Mes.Notification.NotFound': 'Notification not found',
  'Mes.Playlist.AlreadyExist.Code': 'Playlist code already exists',
  'Mes.Playlist.Required.Ids': 'Playlist ID is required',
  'Mes.Playlist.Repeated.Id': 'Repeated playlist ID',
  'Mes.Playlist.NotFound': 'Playlist not found',
  'Mes.Playlist.Required.Name': 'Playlist name is required',
  'Mes.Playlist.OverLength.Name': 'Playlist name is too long',
  'Mes.Playlist.Required.LoginCode': 'Login code is required',
  'Mes.Playlist.Invalid.LoginCode': 'Invalid login code',
  'Mes.Playlist.Required.Code': 'Playlist code is required',
  'Mes.Playlist.OverLength.Code': 'Playlist code is too long',
  'Mes.Playlist.NotWhiteSpace.Code': 'Playlist code must not contain white spaces',
  'Mes.Playlist.NotSpecialCharacter.Code': 'Playlist code must not contain special characters',
  'Mes.Playlist.Delete.Failed': 'Failed to delete playlist',
  'Mes.Playlist.Delete.Successfully': 'Successfully deleted playlist',
  'Mes.Playlist.Search.Failed': 'Failed to search playlist',
  'Mes.Playlist.Search.Successfully': 'Successfully searched playlist',
  'Mes.Playlist.ViewList.Failed': 'Failed to view playlist list',
  'Mes.Playlist.ViewList.Successfully': 'Successfully viewed playlist list',
  'Mes.Playlist.ViewDetail.Failed': 'Failed to view playlist details',
  'Mes.Playlist.ViewDetail.Successfully': 'Successfully viewed playlist details',
  'Mes.Playlist.Update.Failed': 'Failed to update playlist',
  'Mes.Playlist.Update.Successfully': 'Successfully updated playlist',
  'Mes.Playlist.Create.Failed': 'Failed to create playlist',
  'Mes.Playlist.WasUsed': 'Playlist has been used',
  'Mes.Playlist.Required.FieldId': 'Category ID is required',
  'Mes.Playlist.Required.PlayMode': 'Broadcast mode is required',
  'Mes.Playlist.Invalid.PlayMode': 'Invalid broadcast mode',
  'Mes.Playlist.OverLength.Description': 'Description is too long',
  'Mes.Playlist.Required.Sources': 'Playlist sources are required',
  'Mes.PlaylistResourceResource.Repeated.Order': 'Repeated sort order',
  'Mes.PlaylistResource.Required.Order': 'Playlist resource sort order is required',
  'Mes.PlaylistResource.OverLength.Order': 'Playlist resource sort order is too large',
  'Mes.PlaylistResourceResource.NotEnough.Order': 'Sort order must be greater than 1',
  'Mes.PlaylistResource.Required.Duration': 'Playlist resource duration is required',
  'Mes.PlaylistResource.OverLength.Duration': 'Playlist resource duration is too large',
  'Mes.PlaylistResource.NotEnough.Duration': 'Duration must be greater than 1',
  'Mes.PlaylistResource.Required.SourceType': 'Playlist resource type is required',
  'Mes.PlaylistResource.Invalid.SourceType': 'Invalid playlist resource type',
  'Mes.Facility.Required.ParentId': 'Sub-Location code is required',
  'Mes.Facility.Required.Code': 'Location code is required',
  'Mes.Facility.OverLength.Code': 'Location code is too long',
  'Mes.Facility.NotWhiteSpace.Code': 'Location code must not contain white spaces',
  'Mes.Facility.NotSpecialCharacter.Code': 'Location code must not contain special characters',
  'Mes.Facility.AlreadyExist.Code': 'Location code already exists',
  'Mes.Facility.Required.Name': 'Location name is required',
  'Mes.Facility.OverLength.Name': 'Location name is too long',
  'Mes.Facility.Required.OperationStatus': 'Operation status is required',
  'Mes.Facility.Invalid.OperationStatus': 'Invalid operation status',
  'Mes.Facility.NotFound': 'Location not found',
  'Mes.Facility.Import.Failed': 'Import location failed',
  'Mes.Facility.Import.Successfully': 'Import location successfully',
  'Mes.Facility.Delete.Failed': 'Delete location failed',
  'Mes.Facility.Delete.Successfully': 'Delete location successfully',
  'Mes.Facility.Search.Failed': 'Search location failed',
  'Mes.Facility.Search.Successfully': 'Search location successfully',
  'Mes.Facility.ViewList.Failed': 'View location list failed',
  'Mes.Facility.ViewList.Successfully': 'View location list successfully',
  'Mes.Facility.ViewDetail.Failed': 'View location details failed',
  'Mes.Facility.ViewDetail.Successfully': 'View location details successfully',
  'Mes.Facility.Update.Failed': 'Update location failed',
  'Mes.Facility.Update.Successfully': 'Update location successfully',
  'Mes.Facility.Create.Failed': 'Create location failed',
  'Mes.Facility.Create.Successfully': 'Create location successfully',
  'Mes.Facility.NotAllowed': 'Location not allowed',
  'Mes.Facility.StillExist.Medias': 'Location still has media',
  'Mes.Facility.Blocked': 'Location is blocked',
  'Mes.Facility.Required.Ids': 'Location codeentifier is required',
  'Mes.Facility.Repeated.Id': 'Duplicate Location codeentifier',
  'Mes.Supplier.Required.Ids': 'Supplier identifier is required',
  'Mes.Supplier.Repeated.Id': 'Duplicate supplier identifier',
  'Mes.Supplier.NotFound': 'Provider not found',
  'Mes.Supplier.Required.Name': 'Provider name is required',
  'Mes.Supplier.OverLength.Name': 'Provider name is too long',
  'Mes.Supplier.Required.Code': 'Provider code is required',
  'Mes.Supplier.OverLength.Code': 'Provider code is too long',
  'Mes.Supplier.NotWhiteSpace.Code': 'Provider code must not contain whitespace',
  'Mes.Supplier.NotSpecialCharacter.Code': 'Provider code must not contain special characters',
  'Mes.Supplier.AlreadyExist.Code': 'Provider code already exists',
  'Mes.Supplier.Delete.Failed': 'Delete provider failed',
  'Mes.Supplier.Delete.Successfully': 'Delete provider successfully',
  'Mes.Supplier.Search.Failed': 'Search provider failed',
  'Mes.Supplier.Search.Successfully': 'Search provider successfully',
  'Mes.Supplier.ViewList.Failed': 'View provider list failed',
  'Mes.Supplier.ViewList.Successfully': 'View provider list successfully',
  'Mes.Supplier.ViewDetail.Failed': 'View provider details failed',
  'Mes.Supplier.ViewDetail.Successfully': 'View provider details successfully',
  'Mes.Supplier.Update.Failed': 'Update provider failed',
  'Mes.Supplier.Update.Successfully': 'Update provider successfully',
  'Mes.Supplier.Create.Failed': 'Create provider failed',
  'Mes.Supplier.Create.Successfully': 'Create provider successfully',
  'Mes.Template.Required.Ids': 'Template identifier is required',
  'Mes.Template.Repeated.Id': 'Duplicate template identifier',
  'Mes.Template.NotFound': 'Template not found',
  'Mes.Template.Required.Name': 'Template name is required',
  'Mes.Template.OverLength.Name': 'Template name is too long',
  'Mes.Template.Required.Code': 'Template code is required',
  'Mes.Template.OverLength.Code': 'Template code is too long',
  'Mes.Template.NotWhiteSpace.Code': 'Template code must not contain whitespace',
  'Mes.Template.NotSpecialCharacter.Code': 'Template code must not contain special characters',
  'Mes.Template.AlreadyExist.Code': 'Template code already exists',
  'Mes.Template.Delete.Failed': 'Delete template failed',
  'Mes.Template.Delete.Successfully': 'Delete template successfully',
  'Mes.Template.Search.Failed': 'Search template failed',
  'Mes.Template.Search.Successfully': 'Search template successfully',
  'Mes.Template.ViewList.Failed': 'View template list failed',
  'Mes.Template.ViewList.Successfully': 'View template list successfully',
  'Mes.Template.ViewDetail.Failed': 'View template details failed',
  'Mes.Template.ViewDetail.Successfully': 'View template details successfully',
  'Mes.Template.Update.Failed': 'Update template failed',
  'Mes.Template.Update.Successfully': 'Update template successfully',
  'Mes.Template.Create.Failed': 'Create template failed',
  'Mes.Template.Required.ResolutionWidth': 'Resolution width is required',
  'Mes.Template.NotEnough.ResolutionWidth': 'Resolution width must be greater than or equal to 1',
  'Mes.Template.Required.Duration': 'Duration is required',
  'Mes.Template.NotEnough.Duration': 'Duration must be greater than 1',
  'Mes.Template.Invalid.Layout': 'Invalid template layout',
  'Mes.Template.Required.Medias': 'Media is required',
  'Mes.Organization.Required.Account': 'Account login is required',
  'Mes.Organization.Required.TariffPackage': 'Account tariff package is required',
  'Mes.Organization.Required.Code': 'Account code is required',
  'Mes.Organization.OverLength.Code': 'Account code is too long',
  'Mes.Organization.NotWhiteSpace.Code': 'Account code must not contain whitespace',
  'Mes.Organization.NotSpecialCharacter.Code': 'Account code must not contain special characters',
  'Mes.Organization.AlreadyExist.Code': 'Account code already exists',
  'Mes.Organization.Required.Ids': 'Account identifier is required',
  'Mes.Organization.Repeated.Id': 'Duplicate account identifier',
  'Mes.Organization.Required.Name': 'Account name is required',
  'Mes.Organization.OverLength.Name': 'Account name is too long',
  'Mes.Organization.Required.IsAutoRenewal': 'Auto-renewal is required',
  'Mes.Organization.NotFound': 'Account not found',
  'Mes.Organization.Import.Failed': 'Import account failed',
  'Mes.Organization.Import.Successfully': 'Import account successfully',
  'Mes.Organization.Delete.Failed': 'Delete account failed',
  'Mes.Organization.Delete.Successfully': 'Delete account successfully',
  'Mes.Organization.Search.Failed': 'Search account failed',
  'Mes.Organization.Search.Successfully': 'Search account successfully',
  'Mes.Organization.ViewList.Failed': 'View account list failed',
  'Mes.Organization.ViewList.Successfully': 'View account list successfully',
  'Mes.Organization.ViewDetail.Failed': 'View account details failed',
  'Mes.Organization.ViewDetail.Successfully': 'View account details successfully',
  'Mes.Organization.Update.Failed': 'Update account failed',
  'Mes.Organization.Update.Successfully': 'Update account successfully',
  'Mes.Organization.Create.Failed': 'Create account failed',
  'Mes.Organization.Create.Successfully': 'Account created successfully',
  'Mes.Organization.Renewal.Failed': 'Subscription renewal failed',
  'Mes.Organization.Renewal.Successfully': 'Subscription renewed successfully',
  'Mes.Organization.UnUsed.Failed': 'Subscription update failed',
  'Mes.Organization.UnUsed.Successfully': 'Subscription updated successfully',
  'Mes.Organization.WasUsed': 'Account has been used',
  'Mes.Organization.Expired': 'Account has expired',
  'Mes.OrganizationSetting.Required.TimezoneId': 'Timezone ID is required',
  'Mes.Timezone.NotFound': 'Timezone not found',
  'Mes.OrganizationSetting.Required.CreationMode': 'Creation mode is required',
  'Mes.OrganizationSetting.Invalid.CreationMode': 'Invalid creation mode',
  'Mes.TariffPackage.IsNoLimit': 'The subscription is unlimited',
  'Mes.TariffPackage.AlreadyExist.PackageUnUsed': 'Unused subscription already exists',
  'Mes.TariffPackage.Required.Packages': 'Subscription list is required',
  'Mes.TariffPackage.Required.Ids': 'Subscription ID is required',
  'Mes.TariffPackage.Repeated.Id': 'Duplicate subscription ID',
  'Mes.TariffPackage.Required.StartTime': 'Start time is required',
  'Mes.TariffPackage.Required.EndTime': 'End time is required',
  'Mes.TariffPackage.Invalid.EndTime': 'Invalid end time',
  'Mes.TariffPackage.Required.Capacity': 'Capacity is required',
  'Mes.TariffPackage.Invalid.Capacity': 'Invalid capacity',
  'Mes.TariffPackage.NotFound': 'Subscription not found',
  'Mes.TariffPackage.Import.Failed': 'Subscription import failed',
  'Mes.TariffPackage.Import.Successfully': 'Subscription imported successfully',
  'Mes.TariffPackage.Delete.Failed': 'Subscription deletion failed',
  'Mes.TariffPackage.Delete.Successfully': 'Subscription deleted successfully',
  'Mes.TariffPackage.Search.Failed': 'Subscription search failed',
  'Mes.TariffPackage.Search.Successfully': 'Subscription search successful',
  'Mes.TariffPackage.ViewList.Failed': 'View subscription list failed',
  'Mes.TariffPackage.ViewList.Successfully': 'View subscription list successful',
  'Mes.TariffPackage.ViewDetail.Failed': 'View subscription details failed',
  'Mes.TariffPackage.ViewDetail.Successfully': 'View subscription details successful',
  'Mes.TariffPackage.Update.Failed': 'Subscription update failed',
  'Mes.TariffPackage.Update.Successfully': 'Subscription updated successfully',
  'Mes.TariffPackage.Create.Failed': 'Subscription creation failed',
  'Mes.TariffPackage.Create.Successfully': 'Subscription created successfully',
  'Mes.TariffPackage.Renewal.Failed': 'Subscription renewal failed',
  'Mes.TariffPackage.Renewal.Successfully': 'Subscription renewed successfully',
  'Mes.TariffPackage.UnUsed.Failed': 'Subscription update failed',
  'Mes.TariffPackage.UnUsed.Successfully': 'Subscription updated successfully',
  'Mes.TariffPackage.WasUsed': 'Subscription has been used',
  'Mes.TariffPackage.Expired': 'Subscription has expired',
  'Mes.Package.Required.Name': 'Subscription name is required',
  'Mes.Package.OverLength.Name': 'Subscription name is too long',
  'Mes.Package.Required.Price': 'Subscription price is required',
  'Mes.Package.Invalid.Price': 'Invalid subscription price',
  'Mes.Package.Required.PeriodByMonth': 'Subscription period is required',
  'Mes.Package.Invalid.PeriodByMonth': 'Invalid subscription period',
  'Mes.Package.Required.Accessibility': 'Subscription accessibility status is required',
  'Mes.Package.Invalid.Accessibility': 'Invalid subscription accessibility status',
  'Mes.Package.Required.BusinessStatus': 'Subscription business status is required',
  'Mes.Package.Invalid.BusinessStatus': 'Invalid subscription business status',
  'Mes.Package.Required.ApplicableTimeFrom': 'Start time of applicable registration is required',
  'Mes.Package.Required.ApplicableTimeTo': 'End time of applicable registration is required',
  'Mes.Package.Invalid.ApplicableTimeTo': 'Invalid end time of applicable registration',
  'Mes.Package.NotFound': 'Subscription not found',
  'Mes.Device.Required.Volume': 'Volume is required',
  'Mes.Device.Invalid.Volume': 'Invalid volume',
  'Mes.Device.Required.Ids': 'Device ID is required',
  'Mes.Device.Repeated.Id': 'Duplicate device ID',
  'Mes.Device.NotFound': 'Device not found',
  'Mes.Device.Required.LoginCode': 'Login code is required',
  'Mes.Device.Invalid.LoginCode': 'Invalid login code',
  'Mes.Device.Required.Code': 'Device code is required',
  'Mes.Device.OverLength.Code': 'Device code is too long',
  'Mes.Device.NotWhiteSpace.Code': 'Device code must not contain whitespace',
  'Mes.Device.NotSpecialCharacter.Code': 'Device code must not contain special characters',
  'Mes.Device.AlreadyExist.Code': 'Device code already exists',
  'Mes.Device.Invalid.Token': 'Invalid token',
  'Mes.Device.Expired.Token': 'Token has expired',
  'Mes.Newsletter.Required.StartTime': 'Start time is required',
  'Mes.Newsletter.Required.EndTime': 'End time is required',
  'Mes.Newsletter.Invalid.EndTime': 'Invalid end time',
  'Mes.Device.Required.Name': 'Device name is required',
  'Mes.Device.OverLength.Name': 'Device name is too long',
  'Mes.Device.Required.SupplierId': 'Provider ID is required',
  'Mes.Device.Required.OperationStatus': 'Operation status is required',
  'Mes.Device.Invalid.OperationStatus': 'Invalid operation status',
  'Mes.Device.Required.SerialNumber': 'Device SIM serial number is required',
  'Mes.Device.OverLength.SerialNumber': 'Device SIM serial number is too long',
  'Mes.Device.Invalid.SubscriberNumber': 'Invalid SIM subscriber number',
  'Mes.Device.Blocked': 'Device is blocked',
  'Mes.Device.Delete.Failed': 'Device deletion failed',
  'Mes.Device.Delete.Successfully': 'Device deleted successfully',
  'Mes.Device.Search.Failed': 'Device search failed',
  'Mes.Device.Search.Successfully': 'Device search successful',
  'Mes.Device.ViewList.Failed': 'View device list failed',
  'Mes.Device.ViewList.Successfully': 'View device list successful',
  'Mes.Device.ViewDetail.Failed': 'View device details failed',
  'Mes.Device.ViewDetail.Successfully': 'View device details successful',
  'Mes.Device.Update.Failed': 'Device update failed',
  'Mes.Device.Update.Successfully': 'Device updated successfully',
  'Mes.Device.Create.Failed': 'Device creation failed',
  'Mes.Device.Create.Successfully': 'Device created successfully',
  'Mes.Device.Invalid.Id': 'Invalid device ID',
  'Mes.Device.Login.Failed': 'Device login failed',
  'Mes.Device.Login.Successfully': 'Device login successful',
  'Mes.DeviceRefeshToken.Invalid.Token': 'Invalid token',
  'Mes.DeviceRefeshToken.Expired.Token': 'Token has expired',
  'Mes.Device.Reboot.Failed': 'Device reboot failed',
  'Mes.Device.Reboot.Successfully': 'Device reboot successful',
  'Mes.Device.AdjustVolume.Failed': 'Device volume adjustment failed',
  'Mes.Device.AdjustVolume.Successfully': 'Device volume adjusted successfully',
  'Mes.ActivityLogs.Required.Volume': 'Volume is required',
  'Mes.ActivityLogs.Invalid.Volume': 'Invalid volume',
  'Mes.ActivityLogs.Required.NewsletterId': 'Broadcast ID is required',
  'Mes.ActivityLogs.MustBeEmpty.NewsletterId': 'Broadcast ID must be empty',
  'Mes.ActivityLogs.Required.SessionId': 'Session ID is required',
  'Mes.ActivityLogs.Delete.Failed': 'Activity log deletion failed',
  'Mes.ActivityLogs.Delete.Successfully': 'Activity log deleted successfully',
  'Mes.ActivityLogs.Search.Failed': 'Activity log search failed',
  'Mes.ActivityLogs.Search.Successfully': 'Activity log search successful',
  'Mes.ActivityLogs.ViewList.Failed': 'View activity log list failed',
  'Mes.ActivityLogs.ViewList.Successfully': 'View activity log list successful',
  'Mes.ActivityLogs.ViewDetail.Failed': 'View activity log details failed',
  'Mes.ActivityLogs.ViewDetail.Successfully': 'View activity log details successful',
  'Mes.ActivityLogs.Update.Failed': 'Activity log update failed',
  'Mes.ActivityLogs.Update.Successfully': 'Activity log updated successfully',
  'Mes.ActivityLogs.Create.Failed': 'Activity log creation failed',
  'Mes.ActivityLogs.Create.Successfully': 'Activity log created successfully',
  'Mes.Device.Repeated.Code': 'Duplicate device ID',
  'Mes.Newsletter.Playback.Successfully': 'Setup successful',
  'Mes.Media.NotFound': 'Media not found',
  'Mes.Organization.Renewal': 'Subscription renewal successful',
  'Mes.Newsletter.Approve.Successfully': 'Broadcast approved successfully',
  'Mes.Media.Sharing.Successfully': 'Media shared successfully',
  'Mes.MediaShare.Reception': 'Shared media received successfully',
  'Mes.Media.Invalid.Content': 'Invalid content',
  'Mes.Media.Update.Successfully': 'Media updated successfully',
  'Mes.Media.Approve.Successfully': 'Media approved successfully',
  'Mes.Newsletter.Refuse.Successfully': 'Broadcast approval refused successfully',
  'Mes.Media.Refuse.Successfully': 'Media approval refused successfully',
  'Mes.Organization.Truncate.Successfully': 'Account canceled successfully',
  'Mes.TariffPackage.Invalid.EffectiveTime': 'Invalid effective time',
  'Mes.Media.Approved': 'Media approved',
  'Mes.Media.WasUsed': 'Media has been used',
  'Mes.Media.Delete.Successfully': 'Media deleted successfully',
  'Mes.Media.Invalid.ResourceUrl': 'Invalid URL',
  'Mes.Media.MustBeEmpty.Content': 'File must be empty',
  'Mes.Media.InvalidFormat.ResourceUrl': 'URL is in the wrong format',
  'Mes.Media.Create.Failed': 'Failed to create media',
  'Mes.Media.Required.ResourceUrl': 'File URL is required',
  'Mes.Packet.NotFound': 'Package not found',
  'Mes.Media.Crashed.ResourceUrl': 'Incorrect URL',
  'Mes.User.NotFound.Email': 'User email not found',
  'Mes.TariffPackage.NotAllowed': 'This subscription is not allowed to be renewed',
  'Per.Users.Create': 'Add user',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'View user',
  'Mes.Users.Role.UpdateSuccessfully': 'Role updated successfully',
  'Mes.Roles.Add.Successfully': 'Role added successfully',
  'Mes.Organization.NotEnough.Capacity': 'Not enough capacity',
  'Mes.DeviceAuth.Invalid.Code': 'The device code is invalid',
  'Mes.Device.VerifyLoginCode.Successfully': 'Successful device reality ',
  'Mes.Device.ConfirmLoginCode.Successfully': 'True login successfully',
  'Mes.DeviceGroup.AlreadyExist.Name': 'Device group name already exists',
  'Mes.DeviceGroup.Required.Name': 'Device group name is required',
  'Mes.DeviceGroup.OverLength.Name': 'Device group name must not exceed 255 characters',
  'Mes.DeviceGroup.NotFound': 'Device group not found',
  'Mes.DeviceGroup.Create.Successfully': 'Device group created successfully',
  'Mes.DeviceGroup.Update.Successfully': 'Device group updated successfully',
  'Mes.DeviceGroup.Delete.Successfully': 'Device group deleted successfully',
  'Mes.OrganizationSubscription.Cancel.Successfully': 'Successfully canceled the renewal',
  'Mes.OrganizationSubscription.Renew.Successfully': 'Successfully renewed the subscription',
  'Mes.Device.ScreenshotRequest.Successfully': 'Screenshot request created successfully',
  'Mes.ElkActivityLog.Detail.Successfully': 'Screenshot activity log retrieved successfully',
  'Mes.ElkActivityLog.NotFound': 'Screenshot activity log not found',
  'Mes.ElkActivityLog.Required.RequestId': 'Screenshot failed',
  'Mes.ElkActivityLog.Invalid.RequestId': 'Screenshot failed',
  'Mes.ElkActivityLog.Create.Successfully': 'Screenshot activity log created successfully',
  'Mes.Device.NotConnectedYet': 'The device has been disconnected',
};
