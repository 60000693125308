export const logo =
  process.env.MODE === 'qtsc' ? require('./logoQTSC.png') : require('./logo.png');
export const logoProfile = process.env.MODE === 'qtsc' ? require('./logoQTSC.png') : require('./logo-profile.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconDot = require('./dotIcon.png');
export const mapPin = require('./mapPin.svg');
export const img404 = require('./404.png');
export const img403 = require('./403.png');
export const locationBigIcon = require('./dashboard_location_big_icon.png');
export const locationSmallIcon = require('./dashboard_location_small_icon.png');
export const deviceBigIcon = require('./dashboard_device_big_icon.png');
export const deviceSmallIcon = require('./dashboard_device_small_icon.png');
export const newsBigIcon = require('./dashboard_news_big_icon.png');
export const newsSmallIcon = require('./dashboard_news_small_icon.png');
export const organizeBigIcon = require('./dashboard_organize_big_icon.png');
export const organizeSmallIcon = require('./dashboard_organize_small_icon.png');
export const capacityBigIcon = require('./dashboard_capacity_big_icon.png');
export { default as ArrowLeftImg } from './arrow-left.png';
export { default as LogoutIcon } from './LogoutIcon.png';
export { default as iconNewsDetailTable } from './news_detail_table_icon_img.png';
export { default as modalRefuseIcon } from './modal_refuse_icon_img.png';
export { default as deviceRed } from './dashboard_icon_red.png';
export { default as deviceGreen } from './dashboard_icon_green.png';
export { default as errorTariff } from './errorTariff.png';
export { default as defaultTariff } from './defaultTariff.png';
export { default as ImgLogout } from './img-logout.png';
export { default as ImgBackHome } from './img-home.png';
export { default as ImgMediaAudio } from './media_audio.png';
export { default as ImgMediaLive } from './media_live.png';
export { default as ImgMediaRadio } from './media_radio.png';
export { default as defaultMedia } from './audio-thumbnail.png';
export { default as notPlay } from './notPlay.png';
export { default as personImg } from './personImg.png';
export { default as paymentImg } from './paymentImg.png';
export { default as paymentFail } from './paymentFail.png';
export { default as payPalIcon } from './payPalIcon.png';
export { default as packageBg } from './packageBg.png';
export { default as packageDarkBg } from './packageDarkBg.png';
export { default as circleImg } from './circleImg.png';
export { default as packageDropdownIcon } from './packageDropdownIcon.png';
